import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../../components/layouts/Layout';
import { Container, Form } from 'react-bootstrap';
import { connect, ResolveThunks } from 'react-redux';
import { SectionHeader } from '../../../components/sectionHeader/SectionHeader';
import { AppState } from '../../../store/store';
import NextButton from '../../../components/controls/NextButton';
import { FormProvider, useForm } from 'react-hook-form';
import { Radio } from '../../../components/controls/Radio';
import { TextAreaInput } from '../../../components/controls/TextAreaInput';
import { ApiAccidentCulprit, ApiAccidentDetails, ApiClaimIncidentType } from '../../../api';
import { saveAccidentDetailsAction } from './accidentDetailsAction';
import { ModalPopup } from '../../../components/controls/ModalPopup';
import { push } from 'connected-react-router';
import { Translate } from 'react-redux-i18n';
import './AccidentDetails.css';
import {
  trackClaimAccidentDetailsSubmitEvent,
  trackClaimAccidentDetailsViewEvent,
  trackClaimAccidentLocationSelectEvent,
  trackExceptionEvent,
} from '../../../analytics/Analytics';
import {
  LocationInput,
  LocationInputHandlerParam,
} from '../../../components/controls/LocationInputReactGeosuggest';
import { goToNextPage } from '../../../routing/RouteActions';
import dayjs, { Dayjs } from 'dayjs';
import { getLanguageEnumFromType } from '../../../locale/languageMap';
import { LanguageType } from '../../../locale/locale';
import CustomDateTimePicker from '../../../common/components/date-time-picker/CustomDateTimePicker';

export const AccidentDetails: React.FC<React.PropsWithChildren<AccidentDetailsProps>> = ({
  claimId,
  incident,
  accidentDetails,
  color,
  licensePlate,
  saveAccidentDetails,
  navigateHome,
  navigateToNextStep,
  showInsuranceSelection,
  language,
}: AccidentDetailsProps) => {
  useEffect(() => {
    trackClaimAccidentDetailsViewEvent(claimId);
  }, []);

  const methods = useForm({
    defaultValues: {
      accidentDate: accidentDetails.date,
      accidentTime: accidentDetails.time,
      accidentLocation: accidentDetails.address,
      accidentDescription: accidentDetails.description,
      accidentCause: accidentDetails.whoCaused ? accidentDetails.whoCaused : undefined,
      notifiedAuthorities:
        accidentDetails.authoritiesNotified != undefined
          ? accidentDetails.authoritiesNotified
            ? 'yes'
            : 'no'
          : undefined,
      personalInjury:
        accidentDetails.personalInjury != undefined
          ? accidentDetails.personalInjury
            ? 'yes'
            : 'no'
          : undefined,
    },
  });
  const { register, handleSubmit, errors, formState } = methods;
  const geocoderRef: any = useRef(null);
  const beforeSubmit = async (data: React.BaseSyntheticEvent) => {
    handleLocationInvalid(location.label);
    handleDateTimeInvalid();
    await onSubmit(data);
  };

  interface SubmitPayload {
    accidentDescription?: string;
    accidentCause?: ApiAccidentCulprit;
    notifiedAuthorities?: string;
    personalInjury?: string;
  }

  const onSubmit = handleSubmit(async (data: SubmitPayload) => {
    if (claimId && claimId.trim() != '') {
      try {
        const newAddress = geocoderRef.current.input.props.value;
        const isLocationInvalid = newAddress == undefined || newAddress.trim().length == 0;
        await setLocationInvalid(isLocationInvalid);
        if (isLocationInvalid) return;
        let theLocation = location;
        if (accidentDetails.address === newAddress) {
          theLocation = {
            latitude: accidentDetails.location?.latitude
              ? accidentDetails.location?.latitude
              : location.latitude,
            longitude: accidentDetails.location?.longitude
              ? accidentDetails.location?.longitude
              : location.longitude,
            label: newAddress,
          };
        }

        if (dateTime == null || dateError) {
          setDateError(true);
          return;
        }

        const accidentDateTime = dateTime.toDate();

        const apiAccidentDetails: ApiAccidentDetails = {
          accidentDatetime: accidentDateTime,
          address: geocoderRef.current.input.props.value,
          location: { latitude: theLocation.latitude, longitude: theLocation.longitude },
          description: data.accidentDescription,
          whoCaused: data.accidentCause,
          authoritiesNotified:
            data.notifiedAuthorities != undefined && data.notifiedAuthorities === 'yes',
          personalInjury: data.personalInjury != undefined && data.personalInjury === 'yes',
        };
        await saveAccidentDetails(claimId, apiAccidentDetails);
        trackClaimAccidentDetailsSubmitEvent(claimId, accidentDetails);
        navigateToNextStep(incident, showInsuranceSelection);
      } catch (error) {
        trackExceptionEvent(claimId, 'claim accident details submit', error.message);
        setErrorPopup(
          <ModalPopup
            message={<Translate value={'errors.savingAccidentDetailsFailed'} />}
            closeHandler={() => setErrorPopup(<></>)}
          />
        );
      }
    } else {
      setErrorPopup(
        <ModalPopup
          message={<Translate value={'errors.claimNotFound'} />}
          closeHandler={() => {
            setErrorPopup(<></>);
            navigateHome();
          }}
        />
      );
    }
  });

  const handleDateTimeInvalid = () => {
    const dateTimeInvalid = dateTime == null || dateError;
    setDateError(dateTimeInvalid);
  };

  const handleLocationInvalid = (newAddress: string) => {
    const isLocationInvalid = newAddress == undefined || newAddress.trim().length == 0;
    setLocationInvalid(isLocationInvalid);
  };

  const [location, setLocation] = useState({ latitude: 0, longitude: 0, label: '' });
  const onLocationSelect = (data: LocationInputHandlerParam) => {
    setLocation(data);
    handleLocationInvalid(data.label);
    trackClaimAccidentLocationSelectEvent(claimId, data.latitude, data.longitude);
  };

  const [errorPopup, setErrorPopup] = useState(<></>);
  const [isLocationInvalid, setLocationInvalid] = useState(false);

  const displayCulpritQuestion =
    incident === ApiClaimIncidentType.TrafficAccident || incident === ApiClaimIncidentType.Other;
  const displayAuthoritiesQuestion =
    incident !== ApiClaimIncidentType.WindshieldDamage &&
    incident !== ApiClaimIncidentType.TechnicalBreakdown;
  const displayInjuryQuestion =
    incident === ApiClaimIncidentType.TrafficAccident ||
    incident === ApiClaimIncidentType.HitWildAnimal ||
    incident === ApiClaimIncidentType.Other;

  const [dateTime, setDateTime] = useState<Dayjs | null>(
    accidentDetails.date && accidentDetails.time
      ? dayjs(`${accidentDetails.date} ${accidentDetails.time}`)
      : null
  );

  const [dateError, setDateError] = useState(false);

  const onDateChange = (date: Dayjs | null) => {
    if (date != null) {
      setDateError(false);
      setDateTime(date);
    } else {
      setDateError(true);
    }
  };

  return (
    <Layout hideLogo={false} showProgress>
      <Container className='content yaway-container' fluid>
        <SectionHeader text={<Translate value={'accidentDetails.title'} />} />
        <FormProvider {...methods}>
          <Form id='accidentDetailsForm' className='my-4' noValidate onSubmit={beforeSubmit}>
            <Form.Group className='my-4'>
              <Form.Label className='input-lbl'>
                <Translate value={'accidentDetails.dateTime'}></Translate>
              </Form.Label>
              <CustomDateTimePicker
                dateTime={dateTime}
                onDateChange={onDateChange}
                language={language}
                setCallerDateError={(error: boolean) => setDateError(error)}
              ></CustomDateTimePicker>
              {dateError && (
                <Form.Control.Feedback type='invalid'>
                  <Translate value={'accidentDetails.dateError'} />
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className='my-4'>
              <LocationInput
                label={<Translate value={'accidentDetails.location'} />}
                onSelect={onLocationSelect}
                value={accidentDetails.address || ''}
                ref={geocoderRef}
              />
              {isLocationInvalid ? (
                <Form.Control.Feedback type='invalid'>
                  <Translate value={'accidentDetails.mandatoryLocation'} />
                </Form.Control.Feedback>
              ) : (
                <></>
              )}
            </Form.Group>

            <TextAreaInput
              name='accidentDescription'
              label={<Translate value={'accidentDetails.description'} />}
              ref={register({ required: true })}
              isInvalid={!!errors.accidentDescription}
              validationError={
                <Translate value={'accidentDetails.accidentDescriptionIsMandatory'} />
              }
            />

            {displayCulpritQuestion && (
              <Form.Group controlId='accidentCause' className='mt-2'>
                <Form.Label className='font-weight-bold text-muted'>
                  {<Translate value={'accidentDetails.culprit.responsibleParty'} />}
                </Form.Label>
                <Form.Row className='mt-2 d-flex justify-content-start'>
                  <Radio
                    color={color}
                    type='radio'
                    value={ApiAccidentCulprit.Me}
                    label={
                      <Translate
                        value={'accidentDetails.culprit.notifier'}
                        licensePlate={licensePlate}
                      />
                    }
                    id='accidentCause-driver'
                    name='accidentCause'
                    ref={register({ required: true })}
                    isInvalid={!!errors.accidentCause}
                  />
                </Form.Row>
                <Form.Row>
                  <Radio
                    color={color}
                    type='radio'
                    value={ApiAccidentCulprit.Other}
                    label={<Translate value={'accidentDetails.culprit.otherParty'} />}
                    id='accidentCause-other-side'
                    name='accidentCause'
                    ref={register({ required: true })}
                    isInvalid={!!errors.accidentCause}
                  />
                </Form.Row>
                <Form.Row className='d-flex justify-content-start'>
                  <Radio
                    color={color}
                    type='radio'
                    value={ApiAccidentCulprit.Unknown}
                    label={<Translate value={'accidentDetails.culprit.unknown'} />}
                    id='accidentCause-unknown'
                    name='accidentCause'
                    ref={register({ required: true })}
                    isInvalid={!!errors.accidentCause}
                  />
                </Form.Row>
              </Form.Group>
            )}

            {displayAuthoritiesQuestion && (
              <Form.Group controlId='notifiedAuthorities' className='mt-2'>
                <Form.Label className='font-weight-bold text-muted'>
                  {<Translate value={'accidentDetails.authoritiesNotified'} />}
                </Form.Label>
                <Form.Row className='mt-2 d-flex justify-content-start'>
                  <Radio
                    inline
                    name='notifiedAuthorities'
                    id='notifiedAuthorities-yes'
                    color={color}
                    type='radio'
                    label={<Translate value={'accidentDetails.affirmation'} />}
                    value={'yes'}
                    ref={register({ required: true })}
                    isInvalid={!!errors.notifiedAuthorities}
                  />
                  <Radio
                    inline
                    name='notifiedAuthorities'
                    id='notifiedAuthorities-no'
                    color={color}
                    type='radio'
                    label={<Translate value={'accidentDetails.deny'} />}
                    value={'no'}
                    ref={register({ required: true })}
                    isInvalid={!!errors.notifiedAuthorities}
                  />
                </Form.Row>
              </Form.Group>
            )}

            {displayInjuryQuestion && (
              <Form.Group controlId='personalInjury' className='mt-2'>
                <Form.Label className='font-weight-bold text-muted'>
                  {<Translate value={'accidentDetails.personalInjury'} />}
                </Form.Label>
                <Form.Row className='mt-2 d-flex justify-content-start'>
                  <Radio
                    inline
                    name='personalInjury'
                    color={color}
                    type='radio'
                    label={<Translate value={'accidentDetails.affirmation'} />}
                    id='personalInjury-yes'
                    value={'yes'}
                    ref={register({ required: true })}
                    isInvalid={!!errors.personalInjury}
                  />
                  <Radio
                    inline
                    name='personalInjury'
                    color={color}
                    type='radio'
                    label={<Translate value={'accidentDetails.deny'} />}
                    id='personalInjury-no'
                    value={'no'}
                    ref={register({ required: true })}
                    isInvalid={!!errors.personalInjury}
                  />
                </Form.Row>
              </Form.Group>
            )}
            {errorPopup}
          </Form>
        </FormProvider>
        <NextButton
          type='submit'
          form='accidentDetailsForm'
          data-testid='submitBtn'
          loading={formState.isSubmitting}
        />
      </Container>
    </Layout>
  );
};

const mapStateToProps = ({
  claim,
  claimAccidentDetails,
  theme,
  claimIncident,
  vehicleDetails,
  i18n,
}: AppState) => ({
  claimId: claim.claimId,
  accidentDetails: claimAccidentDetails,
  color: theme.color,
  incident: claimIncident.incident,
  licensePlate: vehicleDetails.plateNumber,
  showInsuranceSelection: claim.showInsuranceSelection,
  language: getLanguageEnumFromType(i18n.locale as LanguageType),
});

const mapDispatchToProp = {
  saveAccidentDetails: saveAccidentDetailsAction,
  navigateHome: () => push('/'),
  navigateToNextStep: goToNextPage,
};

export type AccidentDetailsProps = ReturnType<typeof mapStateToProps> &
  ResolveThunks<typeof mapDispatchToProp>;

export default connect(mapStateToProps, mapDispatchToProp)(AccidentDetails);
