import React, { useEffect, useState } from 'react';
import { Button, Container, Modal, ProgressBar } from 'react-bootstrap';
import Layout from '../../../components/layouts/Layout';
import { NextButton } from '../../../components/controls/NextButton';
import { SectionHeader } from '../../../components/sectionHeader/SectionHeader';
import { PhotoList, PhotoListItem } from './PhotoList';
import { deleteFileAction, uploadPhotosAction } from './photosActions';
import { push } from 'connected-react-router';
import { connect, ResolveThunks } from 'react-redux';
import { AppState } from '../../../store/store';
import { ModalPopup } from '../../../components/controls/ModalPopup';
import { Translate } from 'react-redux-i18n';
import { ApiFile } from '../../../api/models';
import {
  trackClaimPhotoDeleteEvent,
  trackClaimPhotosSkipEvent,
  trackClaimPhotosSubmitEvent,
  trackClaimPhotosViewEvent,
  trackExceptionEvent,
} from '../../../analytics/Analytics';
import { goToNextPage } from '../../../routing/RouteActions';

export const PhotosView: React.FC<React.PropsWithChildren<PhotosViewProps>> = ({
  claimId,
  incident,
  goToNextPage,
  themeColor,
  uploadPhotos,
  uploadedPhotos,
  deleteFile,
}: PhotosViewProps) => {
  useEffect(() => {
    trackClaimPhotosViewEvent(claimId);
  }, []);

  useEffect(() => {
    if (claimId === undefined) push('/');
  });

  const [photos, setPhotos] = useState<PhotoListItem[]>(
    uploadedPhotos.map((apiFile) => {
      return { apiFile: apiFile };
    })
  );
  const skip = () => {
    trackClaimPhotosSkipEvent(claimId);
    goToNextPage(incident);
  };
  const [errorPopup, setErrorPopup] = useState(<></>);
  const [loading, setLoading] = useState(false);
  const submitPhotos = async () => {
    setLoading(true);
    try {
      if (photos.length > 0 && claimId != null) {
        const blobPhotoArray = photos
          .filter((photoFile) => photoFile && photoFile.raw != undefined)
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          .map((photoFile) => photoFile.raw!);
        if (blobPhotoArray != undefined) {
          await uploadPhotos(claimId, blobPhotoArray);
        }
        trackClaimPhotosSubmitEvent(claimId, photos.length);
        goToNextPage(incident);
      }
    } catch (error) {
      trackExceptionEvent(claimId || null, 'claim photos submit', error.message);
      setErrorPopup(
        <ModalPopup
          message={<Translate value={'errors.failedToUpload'} />}
          closeHandler={() => setErrorPopup(<></>)}
        />
      );
    } finally {
      setLoading(false);
    }
  };

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const openConfirmModal = () => {
    setShowConfirmModal(true);
    setOpenCamera(false);
  };

  const [isCameraOpen, setOpenCamera] = useState(false);
  const openCamera = () => {
    setShowConfirmModal(false);
    setOpenCamera(true);
  };

  const deletePhoto = (apiFile: ApiFile) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    deleteFile(claimId!, apiFile);
    trackClaimPhotoDeleteEvent(claimId, apiFile.id);
  };

  const confirmModal = (
    <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)} centered>
      <Modal.Body>
        <p className='text-center' data-testid='modal-text'>
          <SectionHeader text={<Translate value={'photos.skipStep'} />} testId='confirm-header' />
          <Translate value={'photos.skipTitle'} />
        </p>
      </Modal.Body>
      <Modal.Footer style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <Button
          variant='outline-secondary'
          onClick={openCamera}
          data-testid='confirm-add-photo-btn'
        >
          <Translate value={'photos.takePhotos'} />
        </Button>
        <Button
          onClick={skip}
          style={{ backgroundColor: themeColor, borderColor: themeColor }}
          data-testid='confirm-skip-btn'
        >
          <Translate value={'photos.skipStep'} />
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <Layout showProgress>
      <Container className='content yaway-container' fluid>
        <p className='text-center'>
          <SectionHeader text={<Translate value={'photos.title'} />} />
        </p>
        <p className='text-muted'>
          <Translate value={'photos.uploadSuggestions.generalViewOfTheVehicle'} />
          <br />
          <Translate value={'photos.uploadSuggestions.damagePicture'} />
          <br />
          <Translate value={'photos.uploadSuggestions.availableDocuments'} />
          <br />
          <Translate value={'photos.uploadSuggestions.driversLicense'} />
          <br />
          <Translate value={'photos.uploadSuggestions.scenePicture'} />
        </p>
        {!loading ? (
          <PhotoList
            themeColor={themeColor}
            photos={photos}
            setPhotos={setPhotos}
            openTheCamera={isCameraOpen}
            deletePhoto={deletePhoto}
          />
        ) : (
          <ProgressBar
            data-testid='progress-bar'
            striped
            animated
            min={0}
            max={photos.length}
            now={uploadedPhotos.length}
          />
        )}
        {errorPopup}
        <NextButton
          color={themeColor}
          text={
            photos.length > 0 ? (
              <Translate value={'next'} />
            ) : (
              <Translate value={'photos.skipForNow'} />
            )
          }
          onClick={photos.length > 0 ? submitPhotos : openConfirmModal}
          loading={loading}
        />
        {confirmModal}
      </Container>
    </Layout>
  );
};

const mapStateToProps = ({ claim, claimPhotos, theme, claimIncident }: AppState) => ({
  claimId: claim.claimId,
  themeColor: theme.color,
  uploadedPhotos: claimPhotos.photos,
  incident: claimIncident.incident,
});

const mapDispatchToProps = {
  uploadPhotos: uploadPhotosAction,
  deleteFile: deleteFileAction,
  goToNextPage: goToNextPage,
};

export type PhotosViewProps = ReturnType<typeof mapStateToProps> &
  ResolveThunks<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(PhotosView);
