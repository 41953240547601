import React, { useEffect } from 'react';
import { Route, RouteProps } from 'react-router';
import { AppState, history } from '../store/store';
import { connect, ConnectedProps } from 'react-redux';
import { AssistanceStorage } from '../common/AssistanceStorage';
import { insurance } from '../common/components/vehicle-details/VehicleDetailsFixture';

const mapStateToProps = ({
  assistanceRequest,
  assistanceIncident,
  map,
  vehicleDetails,
  additionalDetails,
  contactData,
}: AppState) => ({
  incident: assistanceIncident.incident,
  map: map.location,
  vehicle: vehicleDetails,
  email: additionalDetails.email,
  towTruckDriver: assistanceRequest.towTruckDriver,
  submitTime: assistanceRequest.submitTime,
  contactData: contactData,
  assistanceFlowInitialized: assistanceRequest.assistanceFlowInitialized,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type AssistanceRouteProps = RouteProps & PropsFromRedux;

const AssistanceRoute: React.FC<React.PropsWithChildren<AssistanceRouteProps>> = ({
  component,
  incident,
  map,
  submitTime,
  contactData,
  assistanceFlowInitialized,
  vehicle,
  ...rest
}: AssistanceRouteProps) => {
  useEffect(() => {
    if (!AssistanceStorage.hasAssistanceRequest()) history.push('/');
    else if (!incident && !assistanceFlowInitialized) history.push('/assistance/landing');
    else if (
      !vehicle ||
      !vehicle.plateNumber ||
      !vehicle.mark ||
      !insurance.verified ||
      !contactData ||
      !contactData.fullName ||
      !contactData.phonePrefix ||
      !contactData.phoneNumber
    ) {
      history.push('/assistance/vehicle-details');
    } else if (!incident) history.push('/assistance/incidents');
    else if (!map || (map && (!map.lat || !map.lng))) {
      history.push('/assistance/map');
    } else if (!submitTime) history.push('/assistance/additional-details');
    else history.push('/assistance/track-driver');
  }, []);

  return <Route {...rest} render={(props) => <Route {...props} component={component} />} />;
};

export default connector(AssistanceRoute);
