import React from 'react';
import { Button, ButtonProps, Spinner } from 'react-bootstrap';
import './NextButton.css';
import { AppState } from '../../store/store';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';

export interface NextButtonProps {
  opacity?: boolean;
  type?: string;
  form?: any;
  unwrap?: boolean;
  position?: 'sticky' | 'absolute';
  text?: string | JSX.Element;
  loading?: boolean;
}

export const NextButton: React.FC<React.PropsWithChildren<ButtonProps & Props>> = (
  props: ButtonProps & Props
) => {
  const {
    opacity,
    unwrap,
    position,
    text = (<Translate value={'next'} />) as JSX.Element,
    loading,
    color,
    disabled,
  } = props;
  const btnClass = position && position === 'absolute' && 'next-btn-absolute';
  const btn = (
    <Button
      {...props}
      style={{ background: color }}
      size='lg'
      className={`next-btn ${btnClass} ${opacity && 'next-btn-opacity'} ${props.className}`}
      disabled={disabled || loading}
    >
      {loading && (
        <Spinner animation='border' size='sm' as='span' role='status' className='btn-spinner' />
      )}
      {loading && ' '}
      {text}
    </Button>
  );
  return unwrap ? btn : <div className='btn-wrapper'>{btn}</div>;
};

const mapStateToProps = ({ theme }: AppState) => ({
  color: theme.color,
});

export type Props = ReturnType<typeof mapStateToProps> & NextButtonProps;

export default connect(mapStateToProps)(NextButton);
