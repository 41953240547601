import React, { useEffect } from 'react';
import { Route, RouteProps } from 'react-router';
import { AppState, history } from '../store/store';
import { connect, ConnectedProps } from 'react-redux';

const mapStateToProps = ({ claim }: AppState) => ({
  claimId: claim.claimId,
});
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type ClaimRouteProps = RouteProps & PropsFromRedux;

const ClaimRoute: React.FC<React.PropsWithChildren<ClaimRouteProps>> = ({
  component,
  claimId,
  ...rest
}: ClaimRouteProps) => {
  useEffect(() => {
    if (claimId == undefined) history.push('/');
  }, []);

  return <Route {...rest} render={(props) => <Route {...props} component={component} />} />;
};

export default connector(ClaimRoute);
