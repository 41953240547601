import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import '../../../common/components/incidents/incident.css';
import { SectionHeader } from '../../../components/sectionHeader/SectionHeader';
import { connect, ResolveThunks } from 'react-redux';
import { AppState } from '../../../store/store';
import { push } from 'connected-react-router';
import Layout from '../../../components/layouts/Layout';
import { LandingIcon } from '../../../assets/assistance-requests/images/landing/LandingIcon';
import './landing.css';
import { I18n, Translate } from 'react-redux-i18n';
import { setAssistanceFlowIsInitialized } from '../assistanceRequestActions';
import { NextButton } from '../../../components/controls/NextButton';

export const Landing: React.FC<React.PropsWithChildren<LandingProps>> = ({
  color,
  navigateToVehicleDetails,
  setAssistanceInitialized,
}: LandingProps) => {
  const [loading, setLoading] = useState(false);
  const navigateToNextScreen = async () => {
    setLoading(true);
    await setAssistanceInitialized();
    navigateToVehicleDetails();
    setLoading(false);
  };

  return (
    <Layout>
      <Container className='content yaway-container text-center mt-5' fluid>
        <div data-testid='landing-icon' className='content landing-icon-centered'>
          <LandingIcon color={color} />
        </div>
        <SectionHeader
          text={<Translate value='assistanceLanding.title' style={{ fontWeight: 'bold' }} />}
        />
        <div
          dangerouslySetInnerHTML={{ __html: I18n.t('assistanceLanding.description') }}
          className='d-flex align-items-center flex-column m-4 text-default-color'
        />

        <NextButton
          color={color}
          onClick={() => navigateToNextScreen()}
          data-testid='submitBtn'
          loading={loading}
          text={<Translate value={'assistanceLanding.start'} />}
        />
      </Container>
    </Layout>
  );
};

const mapDispatchToProps = {
  navigateToVehicleDetails: () => push('/assistance/vehicle-details'),
  setAssistanceInitialized: setAssistanceFlowIsInitialized,
};

const mapStateToProps = ({ i18n, theme }: AppState) => ({
  language: i18n.locale,
  color: theme.color,
});

export type LandingProps = ResolveThunks<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
