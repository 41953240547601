import React, { useEffect, useState } from 'react';
import { validateLinkAction } from './linkValidationAction';
import { useParams } from 'react-router-dom';
import { connect, ResolveThunks } from 'react-redux';
import { AppState } from '../../../store/store';
import { push } from 'connected-react-router';
import { getErrorMessageKey } from '../../../locale/locale';
import './LinkValidation.css';
import { ExclamationOctagonFill } from 'react-bootstrap-icons';
import { Loader } from '../../../components/loader/Loader';
import { ClientError } from '../../../common/exception/ClientError';
import Layout from '../../../components/layouts/Layout';
import { Translate } from 'react-redux-i18n';
import { getServiceThemeDataByClaimId } from '../../../common/theme/themeActions';
import { ClaimStorage } from '../../../common/ClaimStorage';
import { loadClaimDataAction } from '../claimActions';
import { trackClaimLinkInitializedEvent, trackExceptionEvent } from '../../../analytics/Analytics';

export const linkValidationErrors = {
  SORRY_SOMETHING_WENT_WRONG: 'Sorry, something went wrong',
  LINK_VALIDATION_FAILED: 'Link validation failed',
  LINK_HAS_EXPIRED: 'Link has expired',
  LINK_ALREADY_PROCESSED: 'Link was already processed',
  INVALID_LINK: 'Invalid link',
};

interface PathParameters {
  code: string;
}

export const ClaimLinkValidation: React.FC<React.PropsWithChildren<LinkValidationProps>> = ({
  validateLink,
  loadClaimData,
  navigateToClaimStart,
  fetchServiceTheme,
}: LinkValidationProps) => {
  const [linkState, setLinkState] = useState<string | undefined>(undefined);
  const [showActionHint, setShowActionHint] = useState(false);
  const [isCheckingLink, setIsCheckingLink] = useState(true);
  const { code } = useParams<PathParameters>();

  const handleError = (linkStateMessage: string, showActionHint: boolean) => {
    setLinkState(linkStateMessage);
    setShowActionHint(showActionHint);
  };

  const validateTheLink = async () => {
    try {
      await validateLink(code);

      const claimId = ClaimStorage.getClaimId();
      if (claimId) {
        await fetchServiceTheme(claimId);
        await loadClaimData(claimId);
        trackClaimLinkInitializedEvent(claimId);
        navigateToClaimStart();
      } else handleError(linkValidationErrors.LINK_VALIDATION_FAILED, true);
    } catch (e) {
      trackExceptionEvent(ClaimStorage.getClaimId(), 'claim link validation', e.message);
      if (e instanceof ClientError) {
        handleError(e.message, true);
      } else {
        handleError(linkValidationErrors.SORRY_SOMETHING_WENT_WRONG, false);
      }
    } finally {
      setIsCheckingLink(false);
    }
  };

  useEffect(() => {
    validateTheLink();
  }, []);

  return isCheckingLink ? (
    <Loader />
  ) : (
    <Layout hideLogo>
      <div className='content yaway-container mt-5'>
        <div className='text-center'>
          <ExclamationOctagonFill className='link-status-icon' />
        </div>
        {linkState && (
          <p className='text-center link-status-text'>
            {<Translate value={getErrorMessageKey(linkState)} />}
          </p>
        )}
        {showActionHint && (
          <p className='text-center link-status-text'>
            <Translate value='linkValidation.callToGetNewLink' />
          </p>
        )}
      </div>
    </Layout>
  );
};

const mapDispatchToProps = {
  validateLink: validateLinkAction,
  loadClaimData: loadClaimDataAction,
  fetchServiceTheme: getServiceThemeDataByClaimId,
  navigateToClaimStart: () => push('/claims/incidents'),
};

const mapStateToProps = ({ claim }: AppState) => ({
  claimId: claim.claimId,
});

export type LinkValidationProps = ResolveThunks<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ClaimLinkValidation);
